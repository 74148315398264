
.case {
    width: 100px;
    height: 100px;
    max-height: 100px!important;
    text-align: center;
    line-height: 100px;
    color: #0e3e7c;
    border-radius: 150px;
    user-select: none;
    transition: font-size .2s;
  }
  
.case:hover {
    cursor: crosshair;
    transform: scale(1.4);
    background: radial-gradient(#c8c8e4, #0e3e7c);
}

.case:target, .case:active, .case:focus {
    font-size: 0px;
    background: radial-gradient(#e4c8c8, #7c3e0e);
}

#infos, #infos2 {
    text-align: left;
    margin-top: 25px;
    width: 100%;
    height: auto;
    padding: 9px;
    background-color: rgb(250,250,255,.4);
    border-radius: 12px;
}

#infos p, #infos2 p {
    color: grey;
    text-align: center;
}

#infos2 {
    display: flex;
    flex-wrap: wrap;
    user-select: all;
}

#infos2 span {
    margin-left: auto;
    margin-right: auto;
    width: 48%;
    min-width: 48%;
    padding: 1%;
    text-align: center;
    color: grey;
}

#out {
    max-height: 300vh;
}

.stick {
    position: sticky;
    background-color: rgb(250,250,255,.9);
    top: 5em;
}

.s-l {
    border-radius: 12px 0 0 12px;
}

.s-r {
    border-radius: 0 12px 12px 0;
}