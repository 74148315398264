table {
    margin-top: 12px;
    width: calc(100% + 18px);
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
    box-shadow: 0 0 .4em black;
    background-color: rgb(250,250,255,.4);
}

td, th {
    line-height: 250%;
    user-select: all;
}

td {
    border-left: .1em solid grey;
}

thead {
    border-left: .1em solid black;
    margin: 0;
    padding: 0;
}

th {
    position: sticky;
    top: 4em;
    background-color: black;
    border-left: .1em solid black;
    color: white;
}