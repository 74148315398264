.App {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: url("/public/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.part {
  position: fixed;
  width: 80vw;
  height: 80vh;
  margin-left: 10vw;
  margin-top: 15vh;
  overflow-x: hidden;
  background-color: rgb(250,250,255, .4);
  border-radius: 25px;
  backdrop-filter: blur(1.7em);
}

.ctn {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: fit-content;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

h1 {
  position: sticky;
  user-select: none;
  top: 0px;
  margin: 0;
  line-height: 200%;
  width: 100%;
  height: fit-content;
  background-color: rgb(250,250,255, .4);
  box-shadow: 0 0 .2em black;
  z-index: 1;
}

h2 {
  color: ghostwhite;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.intro {
  text-align: left;
  line-height: 350%;
}

.intro a {
  background-color: ghostwhite;
  color: #0e3e7c;
  border-radius: 4px;
  padding: 4px;
  text-decoration: none;
  transition: all.07s;
}

.intro a:hover {
  border-radius: 4px 4px 0 0;
  border-bottom: .2em solid #0e3e7c;
}

.w50 {
  width: 45%;
  padding-left: 2%;
  padding-right: 2%;
  margin-left: auto;
  margin-right: auto;
}

input, button, select {
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  background-color: rgb(250,250,255,.4);
  height: fit-content!important;
  max-height: fit-content;
  border: none;
  padding: 9px;
  border-radius: 9px;
  font-family: 'Courier New', Courier, monospace;
}

input:active, input:target, input:focus, input:hover {
  outline: none;
}

.buttons {
  width: calc(100% + 18px);
  display: inline-flex;
}

button {
  width: 45%;
  margin-left: auto;
  margin-right: auto;
}

button:hover {
  cursor: pointer;
  filter: invert(1);
}
